<template>
  <div class="recharge">
    <div v-show="!isAuth">
      <Authentication @queryAuthResult="queryAuthResult" />
    </div>
    <div v-show="isAuth">
      <div class="at-once-recharge creat-dialog">
        <el-dialog
          v-model="dialogVisible"
          :title="payment === 'zfb' ? '支付宝支付' : '微信支付'"
          :close-on-click-modal="false"
          @close="wxPayClose"
        >
          <div class="scan-payment">
            <div class="payment-info-item">
              <div class="payment-info-title">
                收款方
              </div>
              <div style="color: #313a46;font-size: 12px;">
                山东正云
              </div>
            </div>
            <div class="payment-info-item">
              <div class="payment-info-title">
                支付金额
              </div>
              <div style="color: #5b8cff;font-size: 24px;font-weight: bold;">
                ￥{{ rechargeTotal }}
              </div>
            </div>
            <div class="payment-info-item">
              <div class="payment-info-title">
                支付方式
              </div>
              <div style="display: flex;align-items: center;">
                <!--              {{ payment === 'zfb' ? '支付宝支付' : '微信支付' }}-->
                <svg-icon icon-class="wx" style="width: 90px;height: 18px;" />
              </div>
            </div>
            <div class="payment-info-item" style="height: 116px;">
              <div class="payment-info-title">
                扫码支付
              </div>
              <div id="qrcode" v-loading="qrcodeLoading" style="width: 116px;height: 116px;" />
            </div>
            <div class="payment-info-item">
              <div class="payment-info-title" />
              <div>
                请打开手机{{ paymentt }}，扫一扫完成扫码支付。
              </div>
            </div>
            <div v-if="isActivity" style="margin-left:180px">
              <span>支付剩余时间：</span>
              <span class="count-down">
                {{
                  (leaveTimeHour > 9 ? leaveTimeHour : '0' + leaveTimeHour) +
                    ':' +
                    (leaveTimeMin > 9 ? leaveTimeMin : '0' + leaveTimeMin) +
                    ':' +
                    (leaveTimeSecond > 9 ? leaveTimeSecond : '0' + leaveTimeSecond)
                }}
              </span>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 10px;">
              <el-button size="small" @click="wxRechargeSuccess">充值成功</el-button>
            </div>
          </div>
        </el-dialog>
      </div>
      <!--    确认充值成功提示框-->
      <div class="creat-dialog confirm-box">
        <el-dialog
          v-model="confirmVisible"
          title="充值提示"
          :close-on-click-modal="false"
        >
          <div class="tip">
            <svg-icon icon-class="recharge-ing" class="svg" />
            正在充值，因网络原因可能会存在3-5分钟延迟，请耐心等待，如长时间未到账可主动联系我们。
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button size="small" @click="zfbRechargeSuccess">充值成功</el-button>
              <el-button size="small" type="primary" @click="giveUpRayment">放弃支付</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <div class="bottom-content">
        <el-card class="box-card" :body-style="{ height: '100%', padding: '30px 20px 20px' }">
          <div class="title">
            线上充值
          </div>
          <div class="recharge-info">
            <div class="info-item">
              <div class="info-title">支付方式</div>
              <div class="zfb" :class="{ 'actived-payment': payment === 'zfb' }" @click="changePayment('zfb')">
                <svg-icon icon-class="zfb" style="width: 100px;height: 100%;" />
              </div>
              <div class="wx" :class="{ 'actived-payment': payment === 'wx' }" @click="changePayment('wx')">
                <svg-icon icon-class="wx" style="width: 125px;height: 100%;" />
              </div>
              <div class="dgzz" :class="{ 'actived-payment': payment === 'dgzz' }" @click="changePayment('dgzz')">
                <svg-icon icon-class="corporate" style="width: 125px;height: 100%;" />
              </div>
            </div>
            <div class="info-item">
              <div class="info-title">选择金额</div>
              <div v-if="!isCorporate" class="discount">
                <div v-for="(item,i) in discountList" :key="i" class="item" :class="activeDiscount === i ? 'select' : ''" @click="discountChange(item,i)">
                  <div class="discount-bg">
                    <img src="@/assets/icon/discount.png" alt="">
                  </div>
                  <div class="discount-detail">
                    {{ item.discount }}
                  </div>
                  <div class="content">
                    <div>{{ item.totalPrice }}元</div>
                    <div class="actual-price">券后价{{ item.actualPrice }}元</div>
                  </div>
                  <div v-show="activeDiscount === i" class="select-icon">
                    <img src="@/assets/icon/select-discount.png" alt="">
                  </div>
                  <div v-show="activeDiscount === i" class="select-icons">
                    <img src="@/assets/icon/select-icons.png" alt="">
                  </div>
                </div>
                <div v-for="(item,i) in amountList" :key="i" class="item amount" :class="activeAmount === i ? 'amount-select' : ''" @click="amountChange(item,i)">
                  <div class="content">
                    <div>{{ item.price }}元</div>
                    <div class="actual-price">实付{{ item.actualPrice }}元</div>
                  </div>
                  <div v-show="activeAmount === i" class="select-icon">
                    <img src="@/assets/icon/select-icon.png" alt="">
                  </div>
                  <div v-show="activeAmount === i" class="select-icons">
                    <img src="@/assets/icon/select-icons.png" alt="">
                  </div>
                </div>
                <div class="item other" @click="otherAmount">
                  <div v-if="!isOtherAmountInput">其他金额</div>
                  <el-form v-else ref="amountForm" :model="amountForm" :rules="rules" class="demo-ruleForm">
                    <el-form-item label="" prop="inputAmount">
                      <el-input ref="input" v-model="amountForm.inputAmount" placeholder="请输入您的充值金额" @input="amountInput" />
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <Corporate v-else />
            </div>
            <div v-show="!isCorporate" class="info-item recharge-btn">
              <div class="info-title" />
              <el-button type="primary" @click="recharge">立即充值</el-button>
            </div>
          </div>
          <div class="tips-content">
            <div>温馨提示：1、您选择使用充值功能说明您已阅读<span><router-link target="_blank" to="/rechargeAgreement">《充值协议》</router-link></span>并完全认同该协议内容。</div>
            <div> 2、账户分为真实金额和赠送金额，所有金额余额不可提现</div>
          </div>
        </el-card>
      </div>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import {
  wechatPromotionPay,
  aliPayPromotionPay,
  getWechatResult,
  getAliPayResult,
  getWechatCode,
  getAliPayCode
} from '@/api/wallet'
import QRCode from 'qrcodejs2'
import Corporate from '@/components/Corporate'
import Authentication from '@/components/Authentication.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Recharge',
  components: { Corporate, Authentication },
  data() {
    const validateAmount = (rule, value, callback) => {
      const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
      const regLimit = /^\d{0,7}(\.\d{0,2})?$/g
      if (value === '' || value === null) {
        this.amountForm.inputAmount = null
        this.isSubmit = false
        this.totalAmount = this.amountForm.inputAmount
        this.actualPrice = this.totalAmount
        this.totalPrice = this.totalAmount
        callback(new Error('请输入您的充值金额'))
      } else {
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的金额'))
        } else if (value == 0) {
          callback(new Error('请输入正确的金额'))
        } else if ((Number(value) > 10000 && this.payment === 'wx')) {
          callback(new Error('微信充值金额不能超过一万元'))
        } else if ((Number(value) > 10000 * 20 && this.payment === 'zfb')) {
          callback(new Error('支付宝充值金额不能超过二十万元'))
        } else {
          callback()
        }
      }
    }
    return {
      isAuth: true,
      amountForm: {
        inputAmount: null
      },
      rules: {
        inputAmount: [{ required: true, trigger: 'blur', validator: validateAmount }]
      },
      isOtherAmountInput: false,
      totalAmount: null,
      inputAmount: null,
      activeAmount: -1,
      amountList: [{
        price: 100,
        actualPrice: 100,
        id: 1
      }, {
        price: 500,
        actualPrice: 500,
        id: 2
      }, {
        price: 1000,
        actualPrice: 1000,
        id: 3
      }, {
        price: 5000,
        actualPrice: 5000,
        id: 4
      }, {
        price: 10000,
        actualPrice: 10000,
        id: 5
      }],
      isActivity: true,
      totalPrice: null,
      actualPrice: null,
      isSubmit: false,
      btnDisabled: false,
      qrcode: null,
      isFinish: false,
      // 支付宝支付-订单id
      zfbOrderId: '',
      wxOrderId: '',
      qrcodeLoading: true,
      isWechatPayment: false,
      rechargeSuccess: false,
      wechatCode: '',
      activeKey: 0,
      activeDiscount: -1,
      discountList: [{
        discount: '满10减9',
        totalPrice: 10,
        actualPrice: 1,
        promotionId: 3879831925655912
      }],
      refreshWxCode: '',
      // 支付方式
      payment: 'zfb',
      // 充值总额
      rechargeTotal: '',
      total: 50,
      pageSize: 10,
      jumpPage: '',
      currentPage: 1,
      dialogVisible: false,
      confirmVisible: false,
      activeName: '1',
      tableData: [],
      multipleSelection: [],
      promotionId: null,
      timer: null,
      endTime: '',
      // 时
      leaveTimeHour: '',
      // 分
      leaveTimeMin: '',
      // 秒
      leaveTimeSecond: '',
      // 定时器
      countDownTimer: null,
      isCorporate: false
    }
  },
  computed: {
    ...mapGetters([
      'auth'
    ]),
    paymentt() {
      if (this.payment === 'zfb') {
        return '支付宝'
      } else {
        return '微信'
      }
    }
  },
  mounted() {
  },
  methods: {
    // 查询认证
    queryAuthResult(data) {
      if (data === 'success') {
        this.isAuth = true
      }
    },
    // 其他金额
    otherAmount() {
      this.isOtherAmountInput = true
      this.activeAmount = -1
      this.activeDiscount = -1
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
      if (this.$refs.amountForm === undefined) {
        this.isSubmit = false
      } else {
        if (this.amountForm.inputAmount === null) {
          this.isSubmit = false
          return
        }
        this.$refs.amountForm.validate((valid) => {
          if (valid) {
            this.isSubmit = true
            this.isWechatPayment = false
            this.clearTimer()
            this.isFinish = false
            if (this.timer != null) {
              clearInterval(this.timer)
              this.timer = null
            }
          } else {
            this.isSubmit = false
          }
        })
      }
    },
    // 输入金额时
    amountInput(val) {
      if (this.$refs.amountForm !== undefined) {
        this.$refs.amountForm.validate((valid) => {
          if (valid) {
            if (this.activeKey !== 2) {
              this.totalAmount = this.amountForm.inputAmount
              this.rechargeTotal = this.totalAmount
              this.totalPrice = this.totalAmount
              this.isWechatPayment = false
              this.clearTimer()
              this.isActivity = false
              this.isSubmit = true
            } else {
              this.isSubmit = false
              this.totalAmount = this.amountForm.inputAmount
              this.rechargeTotal = this.totalAmount
              this.totalPrice = this.totalAmount
              this.isWechatPayment = false
              this.clearTimer()
            }
          } else {
            this.isSubmit = false
          }
        })
      } else {
        this.isSubmit = false
      }
    },
    // 实际支付金额选择
    amountChange(item, i) {
      this.isActivity = false
      this.totalAmount = item.actualPrice
      this.rechargeTotal = item.actualPrice
      this.activeAmount = i
      this.isWechatPayment = false
      this.clearTimer()
      this.rechargeSuccess = false
      this.btnDisabled = false
      this.isFinish = false
      this.isOtherAmountInput = false
      this.activeDiscount = -1
      this.amountForm.inputAmount = null
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.activeKey !== 2 && this.activeAmount !== -1) {
        this.isSubmit = true
      }
    },
    // 优惠方式选择
    discountChange(item, i) {
      this.isActivity = true
      this.rechargeTotal = item.actualPrice
      this.totalPrice = item.totalPrice
      this.activeDiscount = i
      this.isWechatPayment = false
      this.clearTimer()
      this.rechargeSuccess = false
      this.btnDisabled = false
      this.isFinish = false
      this.isOtherAmountInput = false
      this.amountForm.inputAmount = null
      this.promotionId = item.promotionId
      this.activeAmount = -1
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.activeKey !== 2 && this.activeDiscount !== -1) {
        this.isSubmit = true
      }
    },
    // 放弃支付
    giveUpRayment() {
      this.confirmVisible = false
    },
    // zfb充值成功
    zfbRechargeSuccess() {
      getAliPayResult(this.zfbOrderId).then(res => {
        if (res.meta.status === 200) {
          if (res.data) {
            ElMessage.success('充值成功')
            this.confirmVisible = false
          } else {
            ElMessage.warning('未查询到充值信息，请稍后再试')
          }
        }
      }).catch(() => {
        ElMessage.warning('未查询到充值信息，请稍后再试')
      })
      // this.confirmVisible = false
    },
    // 支付宝支付
    zfbPayment() {
      this.clearTimer()
      if (this.isActivity) {
        aliPayPromotionPay(this.promotionId).then(res => {
          const data = res.data
          // this.zfbOrderId = data.orderId
          // const routeData = this.$router.resolve({ path: '/zfbPayment', query: { htmls: data.form }})
          // window.open(routeData.href, '_blank')
          // setTimeout(() => {
          //   this.confirmVisible = true
          // }, 1000)
          if (data.method === 'wxpay') {
            this.payment = 'wx'
            ElMessage.warning('您有未完成的微信支付订单，请先完成！')
            this.dialogVisible = true
            this.endTime = data.expireTime
            this.methodCountDown()
            setTimeout(() => {
              this.wechatCodeShow(data)
            }, 1000)
            this.countDownTimer = setInterval(() => {
              this.methodCountDown()
            }, 1000)
          } else {
            this.aliPayCodeShow(data)
          }
        })
      } else {
        this.codeShowAmount = this.totalAmount
        getAliPayCode(this.totalAmount).then(res => {
          if (this.codeShowAmount !== this.totalAmount) {
            return
          }
          const data = res.data
          this.zfbOrderId = data.orderId
          const routeData = this.$router.resolve({ path: '/zfbPayment', query: { htmls: data.form }})
          window.open(routeData.href, '_blank')
          setTimeout(() => {
            this.confirmVisible = true
          }, 1000)
        })
      }
    },
    // 支付宝二维码显示
    aliPayCodeShow(data) {
      this.zfbOrderId = data.orderId
      const routeData = this.$router.resolve({ path: '/zfbPayment', query: { htmls: data.form }})
      window.open(routeData.href, '_blank')
      setTimeout(() => {
        this.confirmVisible = true
      }, 1000)
    },
    // 微信充值成功
    wxRechargeSuccess() {
      getWechatResult(this.wxOrderId).then(res => {
        if (res.meta.status === 200) {
          if (res.data) {
            ElMessage.success('充值成功')
            this.dialogVisible = false
          } else {
            ElMessage.warning('未查询到充值信息，请稍后再试')
          }
        }
      }).catch(() => {
        ElMessage.warning('未查询到充值信息，请稍后再试')
      })
    },
    // 微信支付
    wxPayment() {
      if (this.isActivity) {
        wechatPromotionPay(this.promotionId).then(res => {
          if (res.meta.status === 200) {
            const data = res.data
            if (data.method === 'wxpay') {
              this.dialogVisible = true
              this.qrcodeLoading = true
              this.$nextTick(() => {
                this.wechatCodeShow(data)
              })
              this.endTime = data.expireTime
              this.methodCountDown()
              this.countDownTimer = setInterval(() => {
                this.methodCountDown()
              }, 1000)
            } else {
              this.payment = 'zfb'
              // this.dialogVisible = false
              clearInterval(this.refreshWxCode)
              ElMessage.warning('您有未完成的支付宝支付订单，请先完成！')
              setTimeout(() => {
                this.aliPayCodeShow(data)
              }, 1000)
            }
          } else {
            this.dialogVisible = false
            clearInterval(this.refreshWxCode)
          }
        }).catch(() => {
          this.dialogVisible = false
          clearInterval(this.refreshWxCode)
        })
      } else {
        this.codeShowAmount = this.totalAmount
        this.qrcodeLoading = true
        getWechatCode(this.totalAmount).then(res => {
          if (res.meta.status === 200) {
            if (this.codeShowAmount !== this.totalAmount) {
              return
            }
            // console.log(res.data)
            const data = res.data
            this.wxOrderId = data.orderId
            document.getElementById('qrcode').innerHTML = ''
            this.qrcode = new QRCode(document.getElementById('qrcode'), {
              text: data.code_url,
              width: 116,
              height: 116,
              colorDark: '#000',
              colorLight: '#fff',
              correctLevel: QRCode.CorrectLevel.H
            })
            this.qrcodeLoading = false
          } else {
            this.dialogVisible = false
            clearInterval(this.refreshWxCode)
          }
        }).catch(() => {
          this.dialogVisible = false
          clearInterval(this.refreshWxCode)
        })
      }
    },
    // 微信支付二维码显示
    wechatCodeShow(data) {
      this.wxOrderId = data.orderId
      document.getElementById('qrcode').innerHTML = ''
      this.qrcode = new QRCode(document.getElementById('qrcode'), {
        text: data.code_url,
        width: 116,
        height: 116,
        colorDark: '#000',
        colorLight: '#fff',
        correctLevel: QRCode.CorrectLevel.H
      })
      this.qrcodeLoading = false
    },
    // 立即充值
    recharge() {
      if (this.auth != 1) {
        this.isAuth = false
        return
      }
      if (this.isActivity) {
        if (this.activeDiscount === -1) {
          ElMessage.warning('请选择金额！')
          return
        }
        if (this.payment === 'wx') {
          this.wxPayment()
          // this.dialogVisible = true
          this.refreshWxCode = setInterval(() => {
            this.wxPayment()
          }, 60000)
        } else if (this.payment === 'zfb') {
          this.zfbPayment()
        }
      } else {
        if (this.isOtherAmountInput) {
          this.$refs.amountForm.validate((valid) => {
            if (valid) {
              if (this.payment === 'wx') {
                this.wxPayment()
                this.dialogVisible = true
                this.refreshWxCode = setInterval(() => {
                  this.wxPayment()
                }, 60000)
              } else if (this.payment === 'zfb') {
                this.zfbPayment()
              }
            }
          })
        } else {
          if (this.payment === 'wx') {
            this.wxPayment()
            this.dialogVisible = true
            this.refreshWxCode = setInterval(() => {
              this.wxPayment()
            }, 60000)
          } else if (this.payment === 'zfb') {
            this.zfbPayment()
          }
        }
      }
    },
    wxPayClose() {
      clearInterval(this.refreshWxCode)
    },
    // 选择支付方式
    changePayment(type) {
      this.payment = type
      if (type === 'dgzz') {
        this.isCorporate = true
      } else {
        this.isCorporate = false
      }
      if (this.isOtherAmountInput) {
        this.$refs.amountForm.validate((valid) => {
          if (valid) {
            return
          }
        })
      }
    },
    // 清除倒计时定时器
    clearTimer() {
      if (this.countDownTimer != null) {
        clearInterval(this.countDownTimer)
        this.countDownTimer = null
      }
    },
    // 计算倒计时
    methodCountDown() {
      const endDate = this.endTime
      const now = parseInt(new Date().getTime() / 1000) + ''
      const leaveTime = (endDate - now)
      this.leaveTimeHour = parseInt(leaveTime / 60 / 60) % 24
      this.leaveTimeMin = parseInt(leaveTime / 60) % 60
      this.leaveTimeSecond = parseInt(leaveTime % 60)
      // 如果倒计时小于等于0时显示0,并清除定时器
      if (
        this.leaveTimeHour <= 0 &&
        this.leaveTimeMin <= 0 &&
        this.leaveTimeSecond <= 0
      ) {
        this.leaveTimeHour = 0
        this.leaveTimeMin = 0
        this.leaveTimeSecond = 0
        this.clearTimer()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .recharge {
    padding: 20px;
    .discount{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    .content{
      font-size: 22px;
      font-weight: 500;
      color: #303133;
      line-height: 26px;
      .actual-price{
        font-weight: 400;
        color: #FF711D;
        line-height: 22px;
        padding-top: 2px;
        font-size: 14px;
      }
    }
    .discount-bg{
      position: absolute;
      left: -1px;
      top: -15px;
    }
    .discount-detail{
      position: absolute;
      left: -1px;
      top: -15px;
      width: 136px;
      text-align: center;
      line-height: 29px;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
    .item {
      margin-bottom: 25px;
      text-align: center;
      padding-top: 26px;
      border: 1px #e7e7e7 solid;
      width: 200px;
      height: 90px;
      border-radius: 4px;
      margin-right: 16px;
      position: relative;
    }
    .select {
      position: relative;
      color: #FF711D;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #FF711D;
      font-size: 0;
    }
    .select-icon{
      position: absolute;
      right: 0;
      bottom: 0;
      display: table-cell;
      img{
        display: block;
        width: 34px;
        height: 36px;
      }
    }
    .select-icons{
      position: absolute;
      right: 2px;
      bottom: 2px;
      display: table-cell;
      img{
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    .amount-select{
      color: #437AEC;
      border: 1px solid #437AEC;
    }
    .amount{
      .actual-price{
        color: #437AEC;
      }
    }
  }
    .at-once-recharge {
      .scan-payment {
        padding: 12px 0 20px;
        color: #313a46;
        font-size: 12px;
        .payment-info-item {
          display: flex;
          height: 32px;
          line-height: 32px;
          & + .payment-info-item {
            margin-top: 12px;
          }
          .payment-info-title {
            width: 160px;
            line-height: 32px;
            font-size: 12px;
            text-align: right;
            margin-right: 20px;
            font-weight: 500;
            color: #313a46;
          }
        }
      }
    }
    .confirm-box {
      .tip {
        height: 154px;
        padding: 20px;
        font-size: 12px;
        color: #737d85;
        line-height: 18px;
        .svg {
          width: 50px;
          height: 50px;
        }
      }
    }
    .top-tip {
      font-size: 12px;
      color: #313a46;
      .v-line {
        height: 12px;
        width: 1px;
        background: #d0d0d0;
        margin: auto 10px;
      }
      .tip-title {
        width: 112px;
        height: 100%;
        line-height: 31px;
        text-align: center;
        background: #e4ebf1;
        border-top: 5px solid #212730;
      }
      .more-tip {
        .svg {
          font-size: 12px;
          color: #313a46;
          margin: auto 5px auto 0;
        }
      }
      .tip-right-bg {
        float: right;
        position: relative;
        bottom: 55px;
        .svg {
          font-size: 120px;
          opacity: .15;
        }
      }
    }
    .bottom-content {
      margin-top: 20px;
      .box-card {
        color: #313a46;
      }
      .title {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .recharge-info {
        padding-bottom: 40px;
        border-bottom: 1px dotted rgba(216,216,216,.8);
        .info-item {
          display: flex;
          .other{
            padding-top: 30px;
            font-size: 16px;
            color: #606266;
            background: #F8F8F8;
            .el-input{
              --el-input-background-color: #F8F8F8;
              --el-input-focus-border: none;
              --el-input-border: none;
            }
            /deep/.el-input__inner{
              text-align: center;
            }
            ::v-deep .el-input__wrapper {
              box-shadow: none;
              background-color: transparent;
            }
          }
          & + .info-item {
            margin-top: 12px;
          }
          .info-title {
            width: 160px;
            line-height: 32px;
            font-size: 12px;
            text-align: right;
            margin-right: 20px;
          }
          .zfb, .wx, .dgzz {
            width: 180px;
            height: 110px;
            border: 1px solid #cfd3e3;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .zfb {
            margin-right: 20px;
          }
          .dgzz {
            margin-left: 20px;
          }
          .actived-payment {
            border-color: #437AEC;
          }
        }
      }
      .table-tip {
        margin: 20px 0 15px;
        font-size: 12px;
        color: #313a46;
        line-height: 24px;
        .title {
          color: #313a46;
          margin-bottom: 20px;
        }
      }
    }
  }
  .down-ul,.file-down-ul {
    .svg {
      margin-right: 3px;
    }
  }
</style>

<style lang="less" scoped>
  .recharge {
    .at-once-recharge {
      .el-dialog {
        width: 600px;
        min-width: 0;
      }
    }
    .confirm-box {
      .el-dialog {
        height: 280px;
        width: 400px;
        min-width: 0;
      }
    }
    .top-tip {
      .el-collapse-item__header {
        height: 36px;
        background: #f5f6fa;
      }
      .el-collapse-item__content {
        padding: 20px;
        height: 110px;
        line-height: 24px;
        font-size: 12px;
        color: #313a46;
        overflow: hidden;
      }
    }
    .bottom-content {
      .recharge-info {
        .info-item {
          .el-input__inner {
            color: #313a46;
            border-color: #cfd3e3;
          }
        }
        .recharge-btn {
          .el-button--primary {
            padding: 10px 31px;
          }
          .el-button--primary:hover {
            background: #437AEC;
            border-color: #437AEC;
          }
        }
      }
      .el-table .select-bg {
        background: #f5f6f9;
      }
      .el-table__column-filter-trigger {
        line-height: 16px;
        i {
          color: #000;
          font-weight: bold;
        }
      }
    }
  }
</style>
